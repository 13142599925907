import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { SEO, SeoObject } from "../components/seo";

export const BasicPage = ({ data }) => {
  let meta: SeoObject = {
    ...data.mdx.frontmatter,
    title: `${data.mdx.frontmatter.title} - Pearl Harbor Experience`,
  };
  return (
    <Layout>
      <SEO meta={meta} />
      <div className="max-w-screen-lg grid gap-6 m-auto py-6">
        <div className="bg-white shadow-lg p-6 my-6 min-w-full w-full prose redbordertop">
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  );
};

export default BasicPage;

export const mdxLayoutTemplateQuery = graphql`
  query PageBySlug($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        slug
        title
      }
    }
  }
`;
